import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.string.iterator.js";
import "core-js/modules/web.dom-collections.iterator.js";
import "core-js/modules/web.dom-collections.for-each.js";

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var twoPoem = function twoPoem() {
  return import("./common/twoPoem");
};

var myFooter = function myFooter() {
  return import("./common/myFooter");
};

export default {
  components: {
    twoPoem: twoPoem,
    myFooter: myFooter
  },
  data: function data() {
    return {
      pagination: {
        current: 1,
        size: 9999,
        order: "title",
        desc: false,
        resourceType: "funny",
        classify: ""
      },
      activeName: 0,
      audio: null,
      playList: null,
      index: null,
      funnys: [{
        classify: "",
        count: null,
        data: [{
          classify: "",
          cover: "",
          url: "",
          title: ""
        }]
      }],
      funny: {
        classify: "",
        title: "",
        cover: "",
        url: ""
      }
    };
  },
  computed: {},
  watch: {},
  created: function created() {
    this.getFunny();
  },
  mounted: function mounted() {},
  beforeDestroy: function beforeDestroy() {
    if (this.audio != null && !this.audio.paused) {
      this.audio.pause();
    }
  },
  methods: {
    getFunny: function getFunny() {
      var _this = this;

      this.$http.get(this.$constant.baseURL + "/webInfo/listFunny").then(function (res) {
        if (!_this.$common.isEmpty(res.data)) {
          _this.funnys = res.data;

          _this.changeFunny(_this.funnys[0].classify);
        }
      }).catch(function (error) {
        _this.$message({
          message: error.message,
          type: "error"
        });
      });
    },
    listFunny: function listFunny() {
      var _this2 = this;

      this.$http.post(this.$constant.baseURL + "/webInfo/listResourcePath", this.pagination).then(function (res) {
        if (!_this2.$common.isEmpty(res.data) && !_this2.$common.isEmpty(res.data.records)) {
          _this2.funnys.forEach(function (funny) {
            if (funny.classify === _this2.pagination.classify) {
              funny.data = res.data.records;

              _this2.$forceUpdate();
            }
          });
        }

        _this2.pagination.classify = "";
      }).catch(function (error) {
        _this2.$message({
          message: error.message,
          type: "error"
        });
      });
    },
    changeFunny: function changeFunny(classify) {
      var _this3 = this;

      this.funnys.forEach(function (funny) {
        if (funny.classify === classify && _this3.$common.isEmpty(funny.data)) {
          _this3.pagination.classify = classify;

          _this3.listFunny();
        }
      });
    },
    playSound: function playSound(src, playList, index) {
      var _this4 = this;

      this.playList = playList;
      this.index = index;

      if (this.audio != null) {
        if (this.audio.src === src) {
          if (this.audio.paused) {
            this.audio.play();
          } else {
            this.audio.pause();
          }
        } else {
          this.audio.pause();
          this.audio.src = src;
          this.audio.load();
          this.audio.play();
        }
      } else {
        this.audio = new Audio(src);
        this.audio.play();

        this.audio.onended = function () {
          _this4.index = _this4.index + 1;

          if (_this4.index < _this4.playList.length) {
            _this4.audio.src = _this4.playList[_this4.index].url;

            _this4.audio.load();

            setTimeout(function () {
              _this4.audio.play();
            }, 3000);
          }
        };
      }
    }
  }
};